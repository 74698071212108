import autosize from 'autosize/dist/autosize';
import { initDefault } from '@/modules/relative-time';

// Bootstrap Plugins
//import Tooltip from 'bootstrap/js/src/tooltip';
import 'bootstrap/js/src/dropdown';
import 'bootstrap/js/src/collapse';

/**
 *
 * @param {Document} document
 * @param {Window} window
 * @param {Console} console
 */
export default function run(document, window, console) {
	// Убираем маркер безъяваскриптовости
	document.body.classList.remove('no-js');

	// Авторазмер всех текстовых полей
	autosize(document.querySelectorAll('textarea'));

	// Относительное время на страницах
	initDefault(document);

	// Бутстраповские тултипы
	//const tooltipList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
	//[...tooltipList].map(tooltipEl => new Tooltip(tooltipEl));
}
