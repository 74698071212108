// @ts-ignore
import Echo from 'laravel-echo';
// @ts-ignore
import axios, { AxiosStatic } from 'axios';
// @ts-ignore
import adapter, { IAdapter } from 'webrtc-adapter';

declare global {
	// eslint-disable-next-line no-unused-vars
	interface Window {
		_: any,
		axios: AxiosStatic,
		Pusher: any,
		Echo: Echo,
		adapter: IAdapter
	}
}

window.adapter = adapter;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import _ from 'lodash';

window._ = _;

/**
 * We’ll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// @ts-ignore
import Pusher from 'pusher-js';

window.Pusher = Pusher;

window.Echo = new Echo({
	broadcaster: 'reverb',
	key: import.meta.env.VITE_REVERB_APP_KEY ?? 'odinn-app-key',
	wsHost: import.meta.env.VITE_REVERB_HOST ?? location.hostname,
	wsPort: import.meta.env.VITE_REVERB_PORT ?? location.port ?? 80,
	wssPort: import.meta.env.VITE_REVERB_PORT ?? location.port ?? 443,
	wsPath: '/ws',
	forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? location.protocol) === 'https',
	enabledTransports: ['ws', 'wss'],
	encrypted: true,
});
