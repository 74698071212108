import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/src/js/vue';
import { APP_VERSION, SENTRY_DSN } from '@/config';
import run from '@/common';
// import * as Vuetify from 'vuetify';
// import 'vuetify/dist/vuetify.min.css';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import '@mdi/font/css/materialdesignicons.css'
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';
import VueKonva from 'vue-konva';
import '../scss/app.scss';
import HighchartsVue from 'highcharts-vue'
// Сентри
Sentry.setTag('js', 'yes');

import './bootstrap';

// При загрузке документа
window.addEventListener('load', () => {
	// Выполняемый при загрузке страниц код
	// Без зависимости от Джейквери

	const userId = window._Auth && window._Auth.id;
	if (userId) {
		Sentry.setUser({ id: userId, username: window._Auth.username, email: window._Auth.email });
	}
	run(document, window, console);
});

// Инициализация Vue
const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

const vuetify = createVuetify({
	components,
	directives,
	defaults: {
		VTextField: {
			variant: 'outlined',
		},
		VComboboxField: {
			variant: 'outlined',
		},
		vSelect: {
			variant: 'outlined',
		},
		VTextarea: {
			variant: 'outlined',
		},
	},
	icons: {
		defaultSet: 'mdi',
	},
});

/* global Ziggy:readonly */
createInertiaApp({
	title: (title) => `${title} — ${appName}`,
	resolve: (name) =>
		resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
	setup({ el, app, props, plugin }) {
		const vue3app = createApp({ render: () => h(app, props) });

		Sentry.init({
			app: vue3app,
			dsn: SENTRY_DSN,
			release: APP_VERSION,
			attachProps: true,
			logErrors: true,
			integrations: [new BrowserTracing()],
			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate: 0.1,
			tracingOptions: { trackComponents: true },
		});

		return vue3app
			.use(plugin)
			.use(ZiggyVue, Ziggy)
			.use(vuetify)
			.use(VueKonva)
			.use(HighchartsVue)
			.use(VuePlyr, { plyr: {} })
			.mount(el);
	},
});

InertiaProgress.init({ color: '#4B5563' });
