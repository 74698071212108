/**
 * Модуль для обновления тегов со временем на странице.
 *
 * @author MaximAL
 * @copyright © MaximAL 2020
 */

import { DateTime, Settings } from 'luxon';

/**
 * Интервал обновления тегов со временем
 * @type {number}
 */
const UPDATE_INTERVAL = 10 * 1000;

/**
 * Селектор по умолчанию для тегов со временем
 * @type {string}
 */
const DEFAULT_SELECTOR = 'time[data-relative=true]';

/**
 * Формат даты и времени.
 * MOMENT — для Moment.js
 * LUXON — для Luxon
 */
const DATETIME_FORMAT = {
	MOMENT: 'LLLL',
	LUXON: {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: 'numeric',
		minute: '2-digit',
		weekday: 'long',
		timeZoneName: 'short',
	},
};

/**
 * Инициализировать дефолтное поведение для тегов со временем.
 * Используется селектор `DEFAULT_SELECTOR`: `<time
 * data-relative="true"></time>`.
 *
 * Время в тегах обновляется раз в `UPDATE_INTERVAL` миллисекунд (по умолчанию:
 * 10 секунд).
 * ```
 * // Например:
 * initDefault();
 * ```
 */
export function initDefault(document: Document): void {
	//moment.locale(navigator.language);
	Settings.defaultLocale = navigator.language || 'en';

	document.querySelectorAll(DEFAULT_SELECTOR).forEach((element: Element) => {
		processElement(element as HTMLElement);
	});

	setInterval(() => {
		document.querySelectorAll(DEFAULT_SELECTOR).forEach((element: Element) => {
			processElement(element as HTMLElement);
		});
	}, UPDATE_INTERVAL);
}

/**
 * Инициализировать дефолтное поведение для заданных тегов со временем.
 * ```
 * // Например:
 * init(document.querySelectorAll('time'));
 * ```
 */
export function init(elements: NodeListOf<HTMLElement>): void {
	//moment.locale(navigator.language);
	Settings.defaultLocale = navigator.language || 'en';

	elements.forEach((element: HTMLElement) => processElement(element));
	setInterval(() => {
		elements.forEach((element: HTMLElement) => processElement(element));
	}, UPDATE_INTERVAL);
}

/**
 * Обработать единожды заданный тег со временем.
 * ```
 * // Например:
 * processElement(document.querySelector('time'));
 * ```
 */
export function processElement(element: HTMLElement): void {
	const noRelative = element.getAttribute('data-relative');
	if (noRelative === 'false') {
		return;
	}
	const value = element.getAttribute('datetime');
	if (value) {
		// Moment.js
		//const time = moment(value);
		//element.setAttribute('title', time.format(DATETIME_FORMAT.MOMENT));
		//element.innerText = time.fromNow();
		// Luxon
		// Если дата передана не в ISO, пробуем сконвертировать:
		// заменяем пробел на T и подставляем в конец Z (UTC)
		const time = DateTime.fromISO(
			value.replace(' ', 'T').replace(/(T\d\d:\d\d:\d\d)$/i, '$1Z'),
		);
		element.setAttribute('title', time.toLocaleString(DATETIME_FORMAT.LUXON));
		if (Math.abs((DateTime.local() - time) / 1000) < 10) {
			element.innerText = 'только что';
		} else {
			element.innerText = time.toRelative();
		}
	}
}

export function dateTimeToRelative(value: string|Date): string {
	let time;
	if (value instanceof Date) {
		time = DateTime.fromJSDate(value);
	} else {
		// Если дата передана не в ISO, пробуем сконвертировать:
		// заменяем пробел на T и подставляем в конец Z (UTC)
		time = DateTime.fromISO(
			value.replace(' ', 'T').replace(/(T\d\d:\d\d:\d\d)$/i, '$1Z'),
		);
	}
	if (Math.abs(DateTime.local().diff(time).milliseconds / 1000) < 10) {
		return 'только что';
	}
	return time.toRelative() || '';
}

export function getISODate(date: Date): string {
	return DateTime.fromJSDate(date).toISODate() || '';
}

export function getLocaleDate(date: Date|string): string {
	const dt = date instanceof Date
		? DateTime.fromJSDate(date)
		: DateTime.fromISO(date.replace(' ', 'T'));
	return dt.toLocaleString(DateTime.DATETIME_SHORT);
}

export function secondsToDuration(seconds: number | null): string | null {
	if (seconds === null) {
		return null;
	}
	const int = Math.floor(seconds);
	const sec = int % 60;
	const min = Math.floor(int / 60) % 60;
	const hrs = Math.floor(int / 60 / 60);
	return (hrs < 10 ? '0' : '') + hrs + ':' +
		(min < 10 ? '0' : '') + min + ':' +
		(sec < 10 ? '0' : '') + sec;
}

export function durationToSeconds(duration: string | null): number | null {
	if (duration === null) {
		return null;
	}
	const parts = duration.split(':');
	if (parts.length === 3) {
		return 60 * 60 * parseInt(parts[0] || '0') +
			60 * parseInt(parts[1] || '0') +
			parseInt(parts[2] || '0');
	}
	if (parts.length === 2) {
		return 60 * 60 * parseInt(parts[0] || '0') +
			60 * parseInt(parts[1] || '0');
	}
	return parseInt(parts[0] || '0');
}
